<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4">
        <base-material-card color="primary" light max-width="100%" width="100%" class="px-5 py-3">
          <template v-slot:heading>
            <div class="text-center">
              <h1 v-if="!recover" class="display-2 font-weight-bold mb-2">
                {{ $t("Autentificare") }}
              </h1>
              <h1 v-else class="display-2 font-weight-bold mb-2">
                {{ $t("Resetare parolă") }}
              </h1>
            </div>
          </template>
          <v-card-text class="text-center">
            <v-form ref="formChange" @submit.prevent>
              <v-text-field
                ref="email"
                v-model="email"
                color="primary"
                autofocus
                :label="$t('Email')"
                :rules="inputValidate"
                prepend-icon="mdi-email"
                @keydown.enter="$refs.password.focus()"
              />
              <v-text-field
                v-if="!recover"
                ref="password"
                v-model="password"
                color="primary"
                :label="$t('Password')"
                :rules="inputValidate"
                prepend-icon="mdi-lock-outline"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append.prevent="show = !show"
                @keydown.enter="login"
              />
            </v-form>
            <v-btn v-show="!recover" color="primary" class="mt-3" @click="login">
              {{ $t("Autentificare") }}
            </v-btn>
            <v-btn v-show="recover" color="primary" class="mt-3" @click="recoverPass">
              {{ $t("Resetare") }}
            </v-btn>
            <br />
            <v-btn v-show="!recover" color="primary" class="mt-3" text @click="recover = true">
              {{ $t("Am uitat parola") }}
            </v-btn>
            <v-btn v-show="recover" color="primary" class="mt-3" text @click="recover = false">
              {{ $t("Autentificare") }}
            </v-btn>

            <!-- <div
              id="google-signin-button"
              class="g-signin2 mt-4"
              data-width="150"
              data-height="30"
              data-longtitle="true"
            /> -->
            <google-login
              class="v-btn--block v-btn--contained v-btn v-size--default"
              block
              button
              :params="params"
              :render-params="renderParams"
              :on-success="onSuccess"
              :on-failure="onFailure"
            >
              Google
            </google-login>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?client_id=
//1024868681617-7028c1529bc1k1mp9psd0hof54mfpne2.apps.googleusercontent.com&
//redirect_uri=https%3A%2F%2Fbibliotecamm.ebibliophil.ro%2Fwp-content%2Fplugins%2Fwordpress-social-login%2Fhybridauth%2F%3Fhauth.done%3DGoogle&
//response_type=code&scope=profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fplus.profile.emails.read&
//access_type=offline&flowName=GeneralOAuthFlow
import axios from "../../../plugins/axios"
import md5 from "js-md5"
import GoogleLogin from "vue-google-login"
import { EventBus } from "../../../EventBus"
export default {
  name: "DashboardDashboard",
  components: { GoogleLogin },
  data() {
    return {
      show: false,
      recover: false,
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      params: {
        client_id: "1024868681617-7028c1529bc1k1mp9psd0hof54mfpne2.apps.googleusercontent.com"
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true
      }
    }
  },
  computed: {
    inputValidate() {
      const rules = []
      rules.push(v => !!v || this.$t("Câmpul este obligatoriu"))
      return rules
    },
    inputValidatePass() {
      const rules = []
      rules.push(v => !!v || this.$t("Câmpul este obligatoriu"))
      rules.push(v => v == this.password || this.$t("Parolele nu sunt identice"))
      return rules
    },
    companyId() {
      return this.$store.getters.companyId
    },
    baseURL() {
      return axios.defaults.baseURL
    }
  },

  metaInfo() {
    return {
      // title: this.title
      // meta: [
      //   {
      //     name: "google-signin-client_id",
      //     content: "1024868681617-7028c1529bc1k1mp9psd0hof54mfpne2.apps.googleusercontent.com"
      //   }
      // ]
      // script: [{ src: "https://apis.google.com/js/platform.js?onload=renderButton" }]
    }
  },
  mounted() {
    if (!window.gapi) {
      this.$log("error")
    }
    //this.$log("window.gapi1 ", window)
    //this.$log("window.gapi2 ", window.gapi)
    // this.$nextTick(() => {
    //   this.$log("window.gapi3 ", window.gapi.signin2)
    // })

    // window.gapi.signin2.render("google-signin-button", {
    //   onsuccess: this.onSignIn
    // })
  },
  updated() {
    this.$log("window.gapi3 ", window.gapi.signin2)
  },
  methods: {
    recoverPass() {
      this.$log("recoverPass")
      if (this.$refs.formChange.validate()) {
        axios
          .get("request_pass_recovery/" + encodeURI(this.email))
          .then(response => {
            this.$log("recover response ", response)
            this.recover = false
            if (response.status == 200) {
              EventBus.$emit("show-alert", {
                message: response.data.msg,
                color: "info",
                timeout: 7000
              })
            }
          })
          .catch(() => {
            this.recover = false
          })
      }
    },
    onSuccess(googleUser) {
      this.$log(googleUser)
      // This only gets the user information: id, name, imageUrl and email
      this.$log(googleUser.getBasicProfile())
      // this.$store.dispatch("login", {
      //         email: googleUser.getBasicProfile().getEmail(),
      //         password: "password",
      //         id_token: googleUser.Zi.id_token,
      //         ga: "1"
      //       })
      //       googleUser.disconnect()
    },
    onFailure(e) {
      this.$log("onFailure ", e)
    },
    // onSignIn(user) {
    //   this.$log("onSignIn ", user)
    //   //const profile = user.getBasicProfile()
    // },
    login() {
      if (this.$refs.formChange.validate()) {
        // .get(
        //     'ebib_marc_usw?where={"fields.201.n.val":"' +
        //       encodeURI(this.email) +
        //       '","fields.201.p.val":"' +
        //       md5(this.password) +
        //       '"}&max_results=1&page=1'
        //   )
        axios
          .post("login_by_emailpass?email=" + encodeURI(this.email) + "&passw=" + md5(this.password))
          .then(response => {
            this.$log("getuser ", response)
            if (response.status == 200) {
              if (response.data.oid) {
                this.$store.commit("accountPass", md5(this.password))
                this.$store.commit("userName", response.data.fullname)
                this.$store.commit("userEmail", response.data.email)
                this.$store.commit("userWebId", response.data.oid)
                if (response.data.favorites != undefined) {
                  this.$log("hasfavorite")
                  this.$store.commit("userFavoritesMon", response.data.favorites.mon)
                  this.$store.commit("userFavoritesAth", response.data.favorites.ath)
                  this.$store.commit("userFavoritesArt", response.data.favorites.art)
                } else {
                  this.$log("nothasfavorite")
                  this.$store.commit("userFavoritesMon", [])
                  this.$store.commit("userFavoritesAth", [])
                  this.$store.commit("userFavoritesArt", [])
                }
                axios
                  .get('ebib_marc_cln?where={"fields.201.n.val":"' + encodeURI(this.email) + '"}&max_results=1&page=1')
                  .then(res => {
                    //this.$log("res ", res)
                    if (res.status == 200) {
                      if (res.data._items.length == 1) {
                        this.$store.commit("userId", res.data._items[0]._id)
                        //this.$store.commit("userFavorites", res.data._items[0].favorites)
                        this.$store.commit("userBarcode", res.data._items[0].fields["500"][0].a[0].val)
                      }
                    }
                  })
                this.email = ""
                this.password = ""
                //this.$store.commit("userId", response.data._items[0].fields['200'][0].a[0].val)
                this.$router.push({ path: "/" })
              } else {
                EventBus.$emit("show-alert", {
                  message: this.$t("Emailul sau parola sunt greșite."),
                  color: "error",
                  timeout: 7000
                })
              }
            } else {
              EventBus.$emit("show-alert", {
                message: this.$t("Autentificare eșuată."),
                color: "error",
                timeout: 7000
              })
            }
          })
      }
    }
  }
}
</script>
<style></style>
